@use '../../GlobalScss/mixins/mixins.scss' as m;
@use '../../GlobalScss/mixins/type-mixins.scss' as tm;
@use '../../GlobalScss/variables.scss' as v;

.detailTable {
  background-color: v.$defaultBg;
  border-radius: 5px;
  padding: 20px;

  @include m.tablet {
    padding: 30px 40px;
  }

  @include m.smallDesktop {
    padding: 30px 30px;
  }

  &__displayFees {
    max-height: 0;
    overflow: hidden;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    transition: max-height 500ms ease, margin 500ms ease, padding 500ms ease;

    .toggleDisplayFees & {
      max-height: 300px;
      padding: 20px 30px;
      margin-top: 40px;
    }
  }

  &-list {
    display: grid;
    column-gap: 50px;
    row-gap: 30px;
    grid-template-columns: 1fr;
    text-align: center;
    color: v.$defaultCopy;

    @include m.tablet {
      row-gap: 40px;
    }

    @include m.smallDesktop {
      text-align: left;
      grid-template-columns: 1fr 1fr;
    }

    .toggleDisplayFees & {
      animation:.5s ease .3s 1 both running fadeIn;
    }

    .toggleDisplayFees__finished & {
      opacity: 1;
      animation: none;
    }

    &__item {
      position: relative;
      @include tm.typeBody--md;

      &:after {
        content: "";
        position: absolute;
        height: 1px;
        background-color: lighten(v.$defaultCopy, 50%); // Default theme
        bottom: -15px;
        left: 0;
        right: 0;

        @include m.tablet {
          bottom: -20px;
        }
      }

      &:last-child:after {
        display: none;
      }

      &:nth-last-child(2):nth-child(odd):after {
        @include m.smallDesktop {
          display: none;
        }
      }

      &__label {
        @include tm.typeBody--md--bd;

        @include m.tablet {
          display: inline-block;
          padding-right: 5px;
        }
      }

      &__data {
        @include m.tablet {
          display: inline-block;
        }
      }

      &__note {
        @include tm.typeBody--md--it;
      }
    }
  }

  &-description {
    @include tm.typeBody--md;
    color: v.$defaultCopy;

    .detailTable-list + & {
      padding-top: 15px;
      margin-top: 10px;
      border-top: 1px solid lighten(v.$defaultCopy, 50%); // Default theme
    }

    // .modalSubtitle + & {
    //   margin-top: 30px;

    //   @include smallMediumDesktop() {
    //     margin-top: 40px
    //   }
    // }
  }
}


.detailTable.detailSection__displayFees {
  max-height: 0;
  overflow: hidden;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  transition: max-height 500ms ease, margin 500ms ease, padding 500ms ease;

 .toggleDisplayFees & {
  max-height: 300px;
  padding: 20px 30px;
  margin-top: 40px;

    .detailTable-list {
      /* @keyframes duration | easing-function | delay |
iteration-count | direction | fill-mode | play-state | name */
      animation:.5s ease .3s 1 both running fadeIn;
    }
  }

  .toggleDisplayFees.toggleDisplayFees__finished & {
    .detailTable-list {
      opacity: 1;
      animation: none;
    }
  }
}